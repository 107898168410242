function ReportPage(props) {
  const { lang, label, chatCenter, toast, info } = props;

  const [loading, setLoading] = React.useState(true);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [allLeadCount, setAllLeadCount] = React.useState(0);
  const [allContactCount, setAllContactCount] = React.useState(0);
  const [allSessionCount, setAllSessionCount] = React.useState(0);
  const [allChatroomCount, setAllChatroomCount] = React.useState(0);
  const [endOffset, setEndOffset] = React.useState(itemOffset + 20);
  const [dateTime, setDateTime] = React.useState({});
  const [csvData, setCsvData] = useState([]);
  const [currentItems, setCurrentItems] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [contactData, setContactData] = React.useState([]);
  const [labelData, setLabelData] = React.useState([]);
  let isMobile = window.innerWidth < 768;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 20) % reportData.length;
    setItemOffset(newOffset);
    setEndOffset(newOffset + 20);
    setCurrentItems(reportData.slice(newOffset, newOffset + 20));
  };

  function formatDateCustom(date, format = "DD-MM-YYYY HH:mm") {
    let m = moment(date);
    let formattedDate = m.format(format);

    if (props.lang.userLang === "th") {
        const gregorianYear = m.year();
        const buddhistYear = gregorianYear + 543;
        formattedDate = formattedDate.replace(String(gregorianYear), String(buddhistYear));
    }

    return formattedDate;
}

  React.useEffect(() => {
    const exportData = [
      [
        lang.lang.__date,
        lang.lang.__contact,
        lang.lang.__chats,
        lang.lang.__contacts,
        lang.lang.__leads,
      ],
    ];

    if (Array.isArray(reportData) && reportData.length > 0) {
      reportData.forEach((val) => {
        const formatDate = formatDateCustom(val.day, "DD MMM YY");

        const pushToExportData = (type, countKey, contactKey, leadKey) => {
          if (val[countKey] || val[contactKey] || val[leadKey]) {
            exportData.push([
              formatDate,
              type,
              val[countKey],
              val[contactKey] || 0,
              val[leadKey] || 0,
            ]);
          }
        };

        pushToExportData(
          "FACEBOOK",
          "FacebookCount",
          "contactFacebook",
          "leadFacebook"
        );
        pushToExportData("LINE", "lineCount", "contactLine", "leadLine");
        pushToExportData("INSTAGRAM", "instagramCount", "contactInstagram", "leadInstagram");
        pushToExportData(
          "WIDGET",
          "widgetCount",
          "contactWidget",
          "leadWidget"
        );
        pushToExportData(
          "R-Chat Link",
          "ChatdayLinkCount",
          "contactChatdayLink",
          "leadChatdayLink"
        );
      });

      setCsvData(exportData);
    }
  }, [currentItems]);

  function CSVDownloader() {
    const downloadCSV = () => {
      let dataArray = [...csvData];
      dataArray.unshift(
        ["R-Chat Report"],
        [
          `${formatDateCustom(
            dateTime.start_date,
            "DD MMM YY"
          )} - ${formatDateCustom(dateTime.end_date, "DD MMM YY")}`,
        ],
        [
          `${contactData.length} ${lang.lang.__contact}, ${labelData.length} ${lang.lang.__label}`,
        ]
      );
      const csv = Papa.unparse(dataArray);
      const BOM = '\uFEFF';
      const csvWithBOM = BOM + csv;

      const blob = new Blob([csvWithBOM], {
        type: "text/csv;charset=UTF-8",
      });

      saveAs(blob, `Report-${formatDateCustom(new Date().toUTCString(), 'DD-MMM-YYYY HH:mm:ss')}.csv`);
    };

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          style={{
            minWidth: 100,
          }}
          onClick={() => downloadCSV()}
        >
          {props.lang.lang.__export_data}
        </Button>
      </div>
    );
  }

  function AllCountBox({ count, text }) {
    return (
      <div class="sp-panel -h50" style={{ minHeight: 100 }}>
        <div class="body -align-center -align-middle">
          <div class="number">{count.toLocaleString()}</div>
          <div class="text">{text}</div>
        </div>
      </div>
    );
  }

  const getReport = (updateDate, source, label, limit) => {
    let startDate = moment(updateDate.start_date).format("YYYY-MM-DD");
    let endDate = moment(updateDate.end_date).format("YYYY-MM-DD");
    setLoading(true);
    shappyMain.log(startDate, endDate);
    let labelId =
      Array.isArray(label) &&
      label.map((val) => {
        return val.label_id;
      });

    axios({
      method: "POST",
      url:
        backend +
        "report/" +
        props.businessData.uid +
        "/" +
        props.businessData.appId,
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.businessData.access,
      },
      data: JSON.stringify({
        offSetTime: new Date().getTimezoneOffset() * -1,
        start: startDate,
        end: endDate,
        source: source,
        label: labelId,
        offset: itemOffset,
        limit: limit,
      }),
    }).then((res) => {
      if (res && res.data && res.data.result === 0) {
        setReportData(res.data.data);
        setAllLeadCount(res.data.allLeadCount);
        setAllContactCount(res.data.allContactCount);
        setAllChatroomCount(res.data.allChatroomCount);
        setAllSessionCount(res.data.allSessionCount);
        setCurrentItems(res.data.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(res.data.data.length / 20));
        setDateTime({ start_date: startDate, end_date: endDate });
        setContactData(source);
        setLabelData(label);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(lang.lang.__report_get_report_fail);
      }
    }).catch((err) => {
      setLoading(false);
      toast.error(lang.lang.__report_get_report_fail);
    });
  };

  const renderData = (data) => {
    return (
      <Data style={{ textAlign: "right" }}>
        {data[5] && (
          <CellData>{data[0] && data[0].toLocaleString() || 0}</CellData>
        )}
        {data[6] && (
          <CellData>{data[1] && data[1].toLocaleString() || 0}</CellData>
        )}
        {data[7] && (
          <CellData>{data[2] && data[2].toLocaleString() || 0}</CellData>
        )}
        {data[8] && (
          <CellData>{data[3] && data[3].toLocaleString() || 0}</CellData>
        )}
        {data[9] && (
          <CellData>{data[4] && data[4].toLocaleString() || 0}</CellData>
        )}
      </Data>
    );
  };

  const isUndefinedCondition = (data) => {
    return (
      (!_.isUndefined(data[0]) && data[0] !== 0) ||
      (!_.isUndefined(data[1]) && data[1] !== 0) ||
      (!_.isUndefined(data[2]) && data[2] !== 0)
    );
  };

  return (
    <div>
      <div>
        <HeaderFilter
          getReport={getReport}
          chatCenter={chatCenter}
          r_chat_link={info && info.is_on}
          label={label}
          lang={lang.lang}
          formatDateCustom={formatDateCustom}
        />
      </div>
      <Detail>
        {loading && (
          <div class="sp-placeholder">
            <div class="sp-text-help">
              <i class="icon icon-circle-notch animate-spin"></i>{" "}
              <span>{lang.lang.__loading}</span>
            </div>
          </div>
        )}
        {!loading && (
          <div>
            {!isMobile && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <div>
                  <span class="sp-label" style={{ fontSize: "20px" }}>
                    {props.lang.lang.__insight_of_chat}
                  </span>
                  <span>
                    &nbsp;&nbsp;
                    {formatDateCustom(dateTime.start_date, "DD MMM YY")} -{" "}
                    {formatDateCustom(dateTime.end_date, "DD MMM YY")}
                  </span>
                </div>
                {reportData.length > 0 ? (
                  <CSVDownloader
                  >
                    {lang.lang.__export_data}
                  </CSVDownloader>
                ) : null}
              </div>
            )}
            <ReportChart
              lang={lang}
              users={reportData}
              formatDateCustom={formatDateCustom}
              dateTime={dateTime}
            />
            <div
              class="analytic-box"
              style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                gridTemplateRows: isMobile ? "1fr 1fr" : "1fr",
                gap: 14,
                margin: "20px 0",
              }}
            >
              <AllCountBox
                count={allChatroomCount}
                text={`${lang.lang.__chats}`}
              />
              <AllCountBox
                count={allContactCount}
                text={`${lang.lang.__contacts}`}
              />
              <AllCountBox count={allLeadCount} text={`${lang.lang.__leads}`} />
            </div>
            {isMobile && Array.isArray(reportData) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: pageCount > 1 ? "space-between" : "flex-end",
                  margin: "0 8px",
                  marginBottom: 20,
                }}
              >
                {pageCount > 1 && (
                  <ReactPaginate.default
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    subContainerClassName={"pages pagination"}
                    marginPagesDisplayed={0}
                    activeClassName={"active"}
                    pageClassName={"page-items"}
                    containerClassName={
                      "pagination justify-content-center my-3"
                    }
                    nextLabel={<i className="icon-right-dir" />}
                    previousLabel={<i className="icon-left-dir" />}
                    renderOnZeroPageCount={null}
                  />
                )}
                {reportData.length > 0 ? (
                  <CSVDownloader
                  >
                    {lang.lang.__export_data}
                  </CSVDownloader>
                ) : null}
              </div>
            )}
            <TableDiv>
              <Table className="table">
                <thead>
                  <tr>
                    <Header
                      style={{
                        textAlign: "middle",
                        width: "14%",
                        left: 0,
                        zIndex: 2,
                      }}
                    >
                      {lang.lang.__date}
                    </Header>
                    <Header
                      style={{
                        width: "10%",
                        left: 76,
                        zIndex: 2,
                      }}
                    >
                      {lang.lang.__contact}
                    </Header>
                    <Header
                      style={{
                        textAlign: "right",
                        width: "16%",
                        zIndex: 1,
                      }}
                    >
                      {lang.lang.__chats}
                    </Header>
                    <Header
                      style={{
                        textAlign: "right",
                        width: "16%",
                        marginRight: "6%",
                        zIndex: 1,
                      }}
                    >
                      {lang.lang.__contacts}
                    </Header>
                    <Header
                      style={{
                        textAlign: "right",
                        width: "16%",
                        zIndex: 1,
                      }}
                    >
                      {lang.lang.__leads}
                    </Header>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentItems) &&
                    currentItems.map((val, index) => {
                      return (
                        <React.Fragment key={val.day}>
                          <TableRow>
                            <td
                              style={{
                                position: "sticky",
                                left: 0,
                                zIndex: 1,
                                backgroundColor: "white",
                              }}
                            >
                              <div style={{ marginTop: 10 }}>
                                {formatDateCustom(val.day, "DD MMM YY")}
                              </div>
                            </td>
                            <Data
                              style={{
                                position: "sticky",
                                left: 76,
                                zIndex: 1,
                                backgroundColor: "white",
                              }}
                            >
                              {isUndefinedCondition([
                                val.FacebookCount,
                                val.leadFacebook,
                                val.contactFacebook,
                              ]) && <CellData>FACEBOOK</CellData>}
                              {isUndefinedCondition([
                                val.lineCount,
                                val.leadLine,
                                val.contactLine,
                              ]) && <CellData>LINE</CellData>}
                              {isUndefinedCondition([
                                val.instagramCount,
                                val.leadInstagram,
                                val.contactInstagram,
                              ]) && <CellData>INSTAGRAM</CellData>}
                              {isUndefinedCondition([
                                val.widgetCount,
                                val.leadWidget,
                                val.contactWidget,
                              ]) && <CellData>WIDGET</CellData>}
                              {isUndefinedCondition([
                                val.ChatdayLinkCount,
                                val.leadChatdayLink,
                                val.contactChatdayLink,
                              ]) && <CellData>R-Chat Link</CellData>}
                            </Data>
                            <Data style={{ textAlign: "right" }}>
                              {isUndefinedCondition([
                                val.FacebookCount,
                                val.leadFacebook,
                                val.contactFacebook,
                              ]) && (
                                <CellData>
                                  {val.FacebookCount.toLocaleString()}
                                </CellData>
                              )}
                              {isUndefinedCondition([
                                val.lineCount,
                                val.leadLine,
                                val.contactLine,
                              ]) && (
                                <CellData>
                                  {val.lineCount.toLocaleString()}
                                </CellData>
                              )}
                              {isUndefinedCondition([
                                val.instagramCount,
                                val.leadInstagram,
                                val.contactInstagram,
                              ]) && (
                                <CellData>
                                  {val.instagramCount.toLocaleString()}
                                </CellData>
                              )}
                              {isUndefinedCondition([
                                val.widgetCount,
                                val.leadWidget,
                                val.contactWidget,
                              ]) && (
                                <CellData>
                                  {val.widgetCount.toLocaleString()}
                                </CellData>
                              )}
                              {isUndefinedCondition([
                                val.ChatdayLinkCount,
                                val.leadChatdayLink,
                                val.contactChatdayLink,
                              ]) && (
                                <CellData>
                                  {val.ChatdayLinkCount.toLocaleString()}
                                </CellData>
                              )}
                            </Data>
                            {renderData([
                              val.contactFacebook,
                              val.contactLine,
                              val.contactInstagram,
                              val.contactWidget,
                              val.contactChatdayLink,
                              isUndefinedCondition([
                                val.FacebookCount,
                                val.leadFacebook,
                                val.contactFacebook,
                              ]),
                              isUndefinedCondition([
                                val.lineCount,
                                val.leadLine,
                                val.contactLine,
                              ]),
                              isUndefinedCondition([
                                val.instagramCount,
                                val.leadInstagram,
                                val.contactInstagram,
                              ]),
                              isUndefinedCondition([
                                val.widgetCount,
                                val.leadWidget,
                                val.contactWidget,
                              ]),
                              isUndefinedCondition([
                                val.ChatdayLinkCount,
                                val.leadChatdayLink,
                                val.contactChatdayLink,
                              ]),
                            ])}
                            {renderData([
                              val.leadFacebook,
                              val.leadLine,
                              val.leadInstagram,
                              val.leadWidget,
                              val.leadChatdayLink,
                              isUndefinedCondition([
                                val.FacebookCount,
                                val.leadFacebook,
                                val.contactFacebook,
                              ]),
                              isUndefinedCondition([
                                val.lineCount,
                                val.leadLine,
                                val.contactLine,
                              ]),
                              isUndefinedCondition([
                                val.instagramCount,
                                val.leadInstagram,
                                val.contactInstagram,
                              ]),
                              isUndefinedCondition([
                                val.widgetCount,
                                val.leadWidget,
                                val.contactWidget,
                              ]),
                              isUndefinedCondition([
                                val.ChatdayLinkCount,
                                val.leadChatdayLink,
                                val.contactChatdayLink,
                              ]),
                            ])}
                          </TableRow>
                          {
                            <TableRow style={{ backgroundColor: "#EEEEEE" }}>
                              <td
                                style={{
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 1,
                                  borderTop: "none",
                                }}
                              ></td>
                              <td
                                style={{
                                  fontWeight: 700,
                                  position: "sticky",
                                  left: 76,
                                  zIndex: 1,
                                  borderTop: "none",
                                }}
                              >
                                {lang.lang.__total}
                              </td>
                              <Data
                                style={{
                                  fontWeight: 700,
                                  textAlign: "right",
                                  borderTop: "none",
                                }}
                              >
                                {val.FacebookCount +
                                  val.lineCount +
                                  val.instagramCount +
                                  val.widgetCount +
                                  val.ChatdayLinkCount || 0}
                              </Data>
                              <Data
                                style={{
                                  fontWeight: 700,
                                  textAlign: "right",
                                  borderTop: "none",
                                }}
                              >
                                {val.contactFacebook +
                                  val.contactLine +
                                  val.contactInstagram +
                                  val.contactWidget +
                                  val.contactChatdayLink || 0}
                              </Data>
                              <Data
                                style={{
                                  fontWeight: 700,
                                  textAlign: "right",
                                  borderTop: "none",
                                }}
                              >
                                {val.leadFacebook +
                                  val.leadLine +
                                  val.leadInstagram +
                                  val.leadWidget +
                                  val.leadChatdayLink || 0}
                              </Data>
                            </TableRow>
                          }
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </Table>
            </TableDiv>
            {!isMobile && Array.isArray(reportData) && pageCount > 1 && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ReactPaginate.default
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  subContainerClassName={"pages pagination"}
                  marginPagesDisplayed={1}
                  activeClassName={"active"}
                  pageClassName={"page-items"}
                  containerClassName={"pagination justify-content-center my-3"}
                  nextLabel={<i className="icon-right-dir" />}
                  previousLabel={<i className="icon-left-dir" />}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        )}
      </Detail>
    </div>
  );
}

const Image = window.styled.img`
  width: 2%;
  height: 2%;
`;

const TableDiv = window.styled.div`
@media (max-width: 767px) {
  width: 100%;
  overflow: auto;
  max-height: 76vh;
}
`;

const Table = window.styled.table`
  width: 100%;
  border-bottom:0.5px solid #E1E7F2;
  white-space: nowrap;
  border-spacing: 0;
`;
const Header = window.styled.th`
  padding: 10px 0;
  position: sticky;
  background-color: white;
  top: 0;
`;

const Data = window.styled.td`
  vertical-align: middle !important;
`;
const CellData = window.styled.div`
margin-bottom: 10px; 
margin-top: 10px;
`;

const TableRow = window.styled.tr`
  background-color: white;
`;

const Detail = window.styled.div`
  padding: 0 14%;
@media (max-width: 767px) {
  padding: 0 1%;
}
`;
